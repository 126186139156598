
import utils from '../../util';

export default {
  name: 'companyLogo',
  props: ['fontSizeType', 'themeOverride', 'isLogin', 'isExporting'],
  data(): {
    logo: {
      src: string;
      companyName: string;
    };
  } {
    return {
      logo: {
        src: '',
        companyName: '',
      },
    };
  },
  created(): void {
    this.getLogo();
  },
  methods: {
    async getLogo(): Promise<void> {
      // allow an override to grab logos that aren't part of the assigned theme
      const logo = await utils.getLogo(this.themeOverride ? this.themeOverride : this.Theme);
      if (logo) {
        this.logo.src = logo.img?.src;
        this.logo.companyName = logo.name;
      }
    },
  },
  computed: {
    textLogoClass(): object {
      return [this.fontSizeType ? this.fontSizeType : 'headline', !this.Theme?.whiteToolbar ? 'white--text' : ''];
    },
    logoClass(): object {
      const isSVG = this.logo.src.split('.').includes('svg');
      return {
        larger: this.largerLogo,
        medium: this.mediumLogo,
        isSVG,
      };
    },
    largerLogo(): boolean {
      return this.Theme?.logo?.large;
    },
    mediumLogo(): boolean {
      return this.logo.companyName.toLowerCase() === 'myanalyticshub';
    },
    isShared(): boolean {
      return this.$store.state.customer?.sharedDashboard;
    },
    showAdvertiserLogo(): boolean {
      if (this.isExporting || this.isShared) return true;
      return this.$route?.name === 'home' || this.$route?.name === 'share';
    },
    advertiserLogo(): string {
      if (this.isShared) return this.$store.state.customer?.sharedSelection?.advertiserLogo;
      if (this.isExporting) return this.$store?.state?.customer?.exportingData?.advertiser?.advertiserLogo;
      return this.$store?.state?.advertiser?.advertiserInfo?.data?.advertiserLogo;
    },
    advertiserName(): string {
      if (this.isShared) return this.$store.state.customer?.sharedSelection?.advertiserName;
      if (this.isExporting) return this.$store?.state?.customer?.exportingData?.advertiser?.name;
      return this.$store?.state?.advertiser?.advertiserInfo?.data?.name;
    },
  },
};
